<template>
  <CCardBody>

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="d-flex flex-md-row flex-column justify-content-center flex-gap-2">
            <!-- Company Filter -->
            <div class="w-100">
              <v-select
                class="v-select-filter"
                placeholder="Select company.."
                v-model="selectedCompany"
                :options="allCompanies"
                @input="companyFilter"
              />
            </div>

            <CInput
              class="w-100 mb-0"
              type="search"
              placeholder="Search.."
              v-model="search"
              @input="searchFilter"
            />

            <!-- Pagination -->
            <div v-if="pages > 1" class="d-none d-xl-block">
              <CPagination
                align="end"
                :dots='false'
                :pages="pages"
                :active-page.sync="activePage"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CAlert v-if="!selectedCompany" color="warning" class="mb-4">
      <div class="d-flex flex-gap-2">
        <CIcon name="cil-hand-point-up" size="lg" />
        <h5>Please select a company..</h5>
      </div>
    </CAlert>

    <template v-else>
      <div v-if="mounting" class="mb-4">
        <CSpinner color="primary" />
      </div>

      <CDataTable
        v-else
        class="table-company-users"
        striped
        hover
        :items="loadedItems"
        :fields="fields"
        :sorter="{ external: true, resetable: false }"
        :itemsPerPageSelect="{
          external: true,
          values: [5, 15, 25, 50, 100, 250, 500],
        }"
        :items-per-page.sync="itemsPerPage"
        :loading="loading"
        :noItemsView="{
          noResults: 'No filtering results are available!',
          noItems: 'No users found!',
        }"
        clickable-rows
        @row-clicked="rowClicked"
        @update:sorter-value="sorterValue"
        @pagination-change="paginationChange"
      >
        <!-- Role -->
        <template #role="{item}">
          <td>
            <strong>{{ item?.company_role }}</strong>
          </td>
        </template>

        <!-- ID -->
        <template #id="{item}">
          <td>
            #{{ item?.id }}
          </td>
        </template>

        <!-- User Icon -->
        <template #image_icon="{item}">
          <td>
            <CLink @click="imageClicked(item)">
              <CImg
                :src="item.image_icon || item.gravatar"
                class="c-avatar-img"
                style="width: 36px; height: 36px"
                placeholderColor="lightgray"
              />
            </CLink>
          </td>
        </template>

        <!-- Name -->
        <template #name="{item}">
          <td>
            {{ item.first_name }}
          </td>
        </template>

        <!-- Contact -->
        <template #contact="{item}">
          <td>
            <div><CIcon name="cil-at" class="mr-1" />{{ item.email }}</div>
            <div><CIcon name="cil-phone" class="mr-1" />{{ item.mobile }}</div>
          </td>
        </template>

        <!-- MenutePay Verified -->
        <template #menutepay_verified_at="{item}">
          <td>
            <span v-if="item.menutepay_verified_at">{{ new Date(item.menutepay_verified_at).toLocaleString() }}</span>
            <span v-else>
              <CLink @click="resendActivationEmail(item.id)">Resend Activation Email</CLink>
            </span>
          </td>
        </template>

        <!-- Email Verified -->
        <template #email_verified_at="{item}">
          <td>
            <span v-if="item.email_verified_at">{{ new Date(item.email_verified_at).toLocaleString() }}</span>
            <span v-else>Not verified</span>
          </td>
        </template>
      </CDataTable>
    </template>


    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

  </CCardBody>
</template>

<script>
import _debounce from 'lodash/debounce';

export default {
  name: "BackendTable",
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "role", _style: "min-width: 96px; width: 96px;" },
        { key: "id", label: "ID", _style: "min-width: 60px; width: 60px;" },
        { key: "image_icon", label: "", sorter: false,  _style: "min-width: 60px; width: 60px;" },
        { key: "name", _style: "min-width: 140px;" },
        { key: "contact", _style : "min-width: 200px;" },
        { key: "menutepay_verified_at", label: "MenutePay Verified", _style : "min-width: 120px;" },
        { key: "email_verified_at", label: "Email Verified", _style : "min-width: 120px;" },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      direction: null,
      search: null,

      loading: true,
      mounting: true,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Company Filter işlemleri için
      allCompanies: [],
    };
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.getAllItems();
    }, 1000);
  },

  async mounted() {
    this.activePage = this.queryPage;

    await this.getAllCompanies();
    if(this.companyId) {
      this.selectedCompany = this.allCompanies.find((c) => c.value == this.companyId)
    }
    await this.getAllItems();
    this.mounting = false;
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage) {
        this.$router.push({ name: this.$route.name, query: { page: this.activePage } });
      }
      this.onTableChange();
    },
  },

  computed: {
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage] },
    queryPage() { return parseInt(this.$route.query.page) || 1 },
    companyId () { return this.$route.params.company_id },
    selectedCompany: {
      get() { return this.$store.state.filters.menutepay.selectedCompany },
      set(value) { this.$store.state.filters.menutepay.selectedCompany = value },
    }
  },

  methods: {
    async getAllItems() {
      if (!this.selectedCompany) {
        return;
      }

      this.loading = true;

      var url = new URL(this.$backend.MENUTEPAY.COMPANIES.USERS.replace("{id}", this.selectedCompany.value)),
        params = {
          paginate: true,
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          'order-by': this.orderBy,
          direction: this.direction,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;

            Object.keys(items.data).map(function(key, index) {
              let item = items.data[index];
              item._classes = !item.menutepay_verified_at ? 'table-warning' : '';
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getAllCompanies() {
      await this.$axios
        .get(this.$backend.MENUTEPAY.COMPANIES.GET_LIST)
        .then((response) => {
          this.allCompanies = response.data;
        })
        .catch(() => { this.allCompanies = [] })
    },


    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.direction = item.column ? (item.asc ? "asc" : "desc") : null;
      this.getAllItems();
    },
    rowClicked(item, index, column, e) {
      if (!['INPUT', 'LABEL', 'BUTTON', 'svg', 'path', 'A', 'IMG', 'SPAN', 'DIV', 'rect', 'STRONG'].includes(e.target.tagName)) {
        this.$router.push({ name: 'User', params: { id: item.id } })
      }
    },

    // Filters
    companyFilter(value) {
      value && this.getAllItems();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.selectedCompany = null;
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },

    async resendActivationEmail(userId) {
      try {
        await this.$axios.post(this.$backend.MENUTEPAY.COMPANIES.RESEND_ACTIVATION_EMAIL
          .replace("{id}", this.selectedCompany.value).replace("{user_id}", userId));
        this.$toast.success("Activation email has been resent.");
      } catch (error) {
        this.$toast.error(`${error.response.data.message}`);
      }
    },
  },
};
</script>

<style>
.table-company-users td {
  padding: 0.5rem 0.5rem;
  vertical-align: middle;
}
</style>
